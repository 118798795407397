import classNames from 'classnames';

import { ScrolledBoxProps } from './scrolled-box.props';
import './scrolled-box.style.scss';

const ScrolledBox = (props: ScrolledBoxProps) => {
    const { content, classList } = props;

    const scrolledBoxClass = classNames('scrolled-box-component', classList);

    return (
        <div className={scrolledBoxClass}>
            <div className="scrolled-box-component__content">{content}</div>
        </div>
    );
};

export default ScrolledBox;
