import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'react-i18next';
import ButtonComponent from 'ui-kit-v2/button/button';
import ScrolledBox from 'ui-kit-v2/scrolled-box/scrolled-box';

import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import './terms-of-use.style.scss';

type TermsOfUsePageProps = {
    data: GatsbyTypes.TermsOfUsePageDataQuery;
};
const TermsOfUsePage = ({ data }: TermsOfUsePageProps) => {
    const { t } = useTranslation();

    const decreaseHtmlHeadingLevelTags = (string: string): string => {
        return string
            .replace(/<h6\b([^>]*)>(.*?)<\/h6>/gi, '<p$1>$2</p>')
            .replace(/<h5\b([^>]*)>(.*?)<\/h5>/gi, '<h6$1>$2</h6>')
            .replace(/<h4\b([^>]*)>(.*?)<\/h4>/gi, '<h5$1>$2</h5>')
            .replace(/<h3\b([^>]*)>(.*?)<\/h3>/gi, '<h4$1>$2</h4>')
            .replace(/<h2\b([^>]*)>(.*?)<\/h2>/gi, '<h3$1>$2</h3>')
            .replace(/<h1\b([^>]*)>(.*?)<\/h1>/gi, '<h2$1>$2</h2>');
    };

    const termsOfService = data?.termsOfService?.nodes[0];
    const websitePrivacyPolicy = data?.websitePrivacyPolicy?.nodes[0];
    const privacyPolicy = data?.privacyPolicy?.nodes[0];

    return (
        <WorkflowLayout isUnauthenticatedFlow={true} className="terms-of-use-workflow-page">
            <LayoutWrapperComponent
                headerAlign="left"
                title={t('pages.birdiPolicies.title')}
                copyText={<Trans i18nKey={'pages.birdiPolicies.copyText'} />}
                workflowLogo={data?.workflowLogo}
                isLogoFixed={true}
            >
                <div className="terms-of-use-workflow-page__content">
                    {termsOfService?.relationships?.field_landing_page_content?.map((paragraph: any, index: number) => {
                        const text = decreaseHtmlHeadingLevelTags(paragraph?.field_rt_rich_text?.processed);
                        return (
                            <ScrolledBox
                                key={`terms-service_${index}`}
                                content={
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: text
                                        }}
                                    />
                                }
                            />
                        );
                    })}

                    {websitePrivacyPolicy?.relationships?.field_landing_page_content?.map(
                        (paragraph: any, index: number) => {
                            const text = decreaseHtmlHeadingLevelTags(paragraph?.field_rt_rich_text?.processed);
                            return (
                                <ScrolledBox
                                    key={`website-privacy-policy_${index}`}
                                    content={
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: text
                                            }}
                                        />
                                    }
                                />
                            );
                        }
                    )}

                    {privacyPolicy?.relationships?.field_landing_page_content?.map((paragraph: any, index: number) => {
                        const text = decreaseHtmlHeadingLevelTags(paragraph?.field_rt_rich_text?.processed);
                        return (
                            <ScrolledBox
                                key={`privacy-policy_${index}`}
                                content={
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: text
                                        }}
                                    />
                                }
                            />
                        );
                    })}
                </div>
                <div className="terms-of-use-workflow-page__footer">
                    <ButtonComponent
                        label={t('pages.birdiPolicies.buttonDecline')}
                        variant="solid"
                        color="neutral"
                        full
                    />{' '}
                    <ButtonComponent
                        label={t('pages.birdiPolicies.buttonAgree')}
                        variant="solid"
                        color="primary"
                        full
                    />
                </div>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default TermsOfUsePage;

export const query = graphql`
    query TermsOfUsePageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        termsOfService: allNodeLandingPage(filter: { path: { alias: { eq: "/website-terms-of-use" } } }) {
            nodes {
                title
                id
                drupal_internal__nid
                path {
                    langcode
                    alias
                }
                drupal_internal__vid
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
        websitePrivacyPolicy: allNodeLandingPage(filter: { path: { alias: { eq: "/website-privacy-policy" } } }) {
            nodes {
                title
                id
                drupal_internal__nid
                path {
                    langcode
                    alias
                }
                drupal_internal__vid
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
        privacyPolicy: allNodeLandingPage(filter: { path: { alias: { eq: "/notice-of-privacy-practices" } } }) {
            nodes {
                title
                id
                drupal_internal__nid
                path {
                    langcode
                    alias
                }
                drupal_internal__vid
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
        workflowLogo: file(relativePath: { eq: "assets/images/birdi-logo.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG, layout: CONSTRAINED, width: 75)
            }
        }
    }
`;
